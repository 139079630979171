import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="text-center">
        <h1 className="text-white text-6xl font-bold my-8 p-3">404</h1>
        <h2 className="text-white text-2xl font-bold my-8 p-3">
          Whoops looks like we couldn&apos;t find what you&apos;re looking for
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
